import axios from 'axios';
import auth from './auth';

export const endpoint = 'https://api.ourguide.xyz/';
export const api = axios.create({
  baseURL: endpoint,
  headers: {
    Authorization: `Bearer ${auth.getToken()}`,
  },
});
export const getErrorMessage = (err: any) => err.response.data.error.message;
