import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';
import { QueryParamProvider } from 'use-query-params';

import {
  Root,
  Test,
  Password,
  RequestPassword,
  Login,
  Logout,
  Register,
  CourseIdx,
  CourseList,
  CourseLearn,
  Mypage,
  MypageCourses,
  MypageCertificate,
  Privacy,
  Service,
  NotFound,
  Membership,
  Profile,
  Support,
  SupportList,
  SupportSubmit,
  Cart,
  CartPayment,
} from '../pages';

import PrivateRoute from '../components/PrivateRoute';
import { api } from '../utils/api';

const App: React.FC = () => (
  <RecoilRoot>
    <SWRConfig
      value={{
        fetcher(url, params) {
          return api({
            method: 'GET',
            url,
            params,
          }).then((res) => res.data);
        },
      }}
    >
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/" component={Root} />
            <Route exact path="/test" component={Test} />
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/logout" component={Logout} />
            <Route
              exact
              path="/auth/find-password"
              component={RequestPassword}
            />
            <Route exact path="/auth/password" component={Password} />
            <Route exact path="/auth/register" component={Register} />
            <Route exact path="/course" component={CourseList} />
            <Route exact path="/course/:courseIdx" component={CourseIdx} />
            <Route
              exact
              path="/course/:courseIdx/learn"
              component={CourseLearn}
            />
            <Route exact path="/policy/privacy" component={Privacy} />
            <Route exact path="/policy/service" component={Service} />
            <Route exact path="/membership" component={Membership} />
            <PrivateRoute exact path="/mypage" component={Mypage} />
            <PrivateRoute exact path="/mypage/profile" component={Profile} />
            <PrivateRoute
              exact
              path="/mypage/courses"
              component={MypageCourses}
            />
            <PrivateRoute
              exact
              path="/mypage/certificate/:courseId"
              component={MypageCertificate}
            />
            <PrivateRoute exact path="/mypage/support" component={Support} />
            <PrivateRoute
              exact
              path="/mypage/support/list"
              component={SupportList}
            />
            <PrivateRoute
              exact
              path="/mypage/support/submit"
              component={SupportSubmit}
            />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/cart/payment" component={CartPayment} />
            <Route path="" component={NotFound} />
          </Switch>
        </QueryParamProvider>
      </BrowserRouter>
    </SWRConfig>
  </RecoilRoot>
);

export default App;
