import loadable from '@loadable/component';

export const Root = loadable(() => import('./Root'));
export const Test = loadable(() => import('./Test'));

export const Login = loadable(() => import('./auth/Login'));
export const Logout = loadable(() => import('./auth/Logout'));
export const Password = loadable(() => import('./auth/Password'));
export const RequestPassword = loadable(() => import('./auth/RequestPassword'));
export const Register = loadable(() => import('./auth/Register'));

export const Cart = loadable(() => import('./cart/Cart'));
export const CartPayment = loadable(() => import('./cart/CartPayment'));

export const CourseIdx = loadable(() => import('./course/CourseIdx'));
export const CourseList = loadable(() => import('./course/CourseList'));
export const CourseLearn = loadable(() => import('./course/CourseLearn'));

export const Privacy = loadable(() => import('./policy/Privacy'));
export const Service = loadable(() => import('./policy/Service'));

export const Membership = loadable(() => import('./membership/Membership'));

export const Mypage = loadable(() => import('./mypage/Mypage'));
export const MypageCourses = loadable(() => import('./mypage/MypageCourses'));
export const MypageCertificate = loadable(() =>
  import('./mypage/MypageCertificate'),
);
export const Profile = loadable(() => import('./mypage/Profile'));

export const Support = loadable(() => import('./mypage/support/Support'));
export const SupportList = loadable(() => import('./mypage/support/List'));
export const SupportSubmit = loadable(() => import('./mypage/support/Submit'));

export const NotFound = loadable(() => import('./NotFound'));
