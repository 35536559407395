const { parse } = JSON;

interface IAuth {
  clear(key: string): void;
  removeAuth(): void;
  get(key: string): JSON;
  setToken(token: string): void;
  getToken(): any;
  setEmail(key: string): void;
  getEmail(): any;
}

const auth: IAuth = {
  clear(key) {
    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }
    return null;
  },

  removeAuth() {
    // return localStorage.clear();
    this.clear('token');
    this.clear('email');
    return;
  },

  get(key) {
    return parse(localStorage.getItem(key) as string);
  },

  setToken(token) {
    return localStorage.setItem('token', token);
  },

  getToken() {
    return localStorage.getItem('token');
  },

  setEmail(email) {
    return localStorage.setItem('email', email);
  },

  getEmail() {
    return localStorage.getItem('email');
  },
};

export default auth;
